.button {
    background-color: #04c35c;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100%;
    height: 50px;
}
