.container {
    position: relative;
    width: 450px;
    background: #ffffff;
    padding: 50px;
    margin: 66px auto;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
}

.text {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #1a202c;
}
