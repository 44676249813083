.star {
    display: none;
}

.star.active {
    display: initial;
    color: #f7685b;
}

.labelActive {
    display: none;
}
