.container {
    height: calc(100% - 50px);
}

.toolbar {
    min-height: 50px;
}

/* @media screen and (max-width: 1280px) {
    .container {
        height: 100%;
    }
} */
