.formGroup {
    height: 120px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.input {
    width: 100%;
    height: 50px;
    font-style: normal;
    line-height: 16px;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 16px;
    padding-right: 16px;
}

.input::placeholder {
    color: rgba(194, 207, 224, 1);
}
.input:focus {
    outline: none !important;
}

.input[type="time"]::-webkit-datetime-edit-text {
    padding: 19px 4px;
}

.input[type="time"]::-webkit-datetime-edit-text {
    padding: 19px 4px;
}

.input[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0.5;
}

.input.active {
    border-color: #f7685b;
}

.label {
    margin-bottom: 11px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    padding: 0;
}

.alert {
    display: none;
    align-items: center;
    margin-top: 5px;
    padding-left: 1px;
}

.alert.active {
    display: flex;
}

.star {
    display: none;
    color: #f7685b;
}

.star.active {
    display: initial;
}

.icon {
    font-size: 12px;
    color: #f7685b;
    padding-bottom: 2px;
    margin-right: 8px;
}

.labelError {
    color: #f7685b;
    font-size: 12px;
}

@media screen and (max-width: 1280px) {
    .label {
        font-size: 11px;
    }

    .input {
        padding-right: 8px;
        font-size: 13px;
    }
}
