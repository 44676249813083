.containerRadios {
    display: flex;
    justify-content: space-between;
    margin: 30px auto 56px auto;
}

.containerCheckbox {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}

.checkbox {
    margin-right: 10px;
}

.checkboxLabel > a {
    color: #04c35c;
    text-decoration: none;
}

.button {
    margin-top: 45px;
}

.alertLabel {
    font-size: 14px;
    height: 24px;
    text-align: center;
    margin: 0;
    left: 50%;
    color: #f7685b;
}
