.container {
    width: 100%;
    height: 77%;
    padding: 15px 15px 0px 15px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
}
.images {
    display: flex;
    flex-direction: row;
    overflow: none;
}
.imagePreview {
    width: 140px;
    height: 78px;
}
.imagePreview > img {
    width: 90%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}
