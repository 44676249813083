.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    font-weight: bold;
    font-size: 60px;
    line-height: 82px;
    font-style: normal;
    color: rgba(129, 140, 139, 1);
    font-family: Nunito;
}

.body {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    color: #2d4642;
}
.containerButton {
    display: flex;
    gap: 16px;
}

.linkToHome {
    background-color: rgba(4, 195, 92, 1);
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
    padding: 16px 24px;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    border-radius: 5px;
}

.linkToHome:hover {
    color: rgba(255, 255, 255, 1);
}

.linkToFacebook {
    background-color: rgba(255, 255, 255, 1);
    text-decoration: none;
    color: rgba(4, 195, 92, 1);
    padding: 16px 24px;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    border-radius: 5px;
    border: 1px solid #c6cecd;
}

.linkToFacebook:hover {
    color: rgba(4, 195, 92, 1);
}

.linkToHome > p,
.linkToFacebook > p {
    margin: 0;
}
