.warning {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
.requestChangePassword {
    margin-bottom: 15px;
}
.buttonConfirmChange {
    margin-top: 45px;
}
.alertLabel {
    font-size: 14px;
    height: 24px;
    text-align: center;
    margin: 0;
    left: 50%;
    color: #f7685b;
}
