.form {
    display: flex;
    flex-direction: column;
}

.formGroup {
    display: flex;
    justify-content: space-between;
    margin-bottom: 37px;
}

.checkbox {
    border-radius: 50%;
}

.linkForgotPassword {
    text-decoration: none;
    color: #2c5282;
}

.footerForm {
    position: relative;
    margin-top: 152px;
    display: flex;
    justify-content: center;
}

.linkSignUp {
    color: #04c35c;
    text-decoration: none;
}

.alertLabel {
    font-size: 14px;
    height: 24px;
    text-align: center;
    margin: 0;
    left: 50%;
    color: #f7685b;
}
