.link {
    text-decoration: none;
    color: #334d6e;
}

.linkLogout {
    text-decoration: none;
    color: rgba(247, 104, 91, 1);
    width: 100%;
}

.linkLogout:hover {
    color: rgba(247, 104, 91, 1);
}

.link:hover * {
    color: #04c35c;
}

.link.active * {
    color: #04c35c;
}
