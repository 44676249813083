.boxNumber {
    width: 20px;
    height: 20px;
    border: 1px solid #04c35c;
    background-color: #04c35c;
    border-radius: 4px;
}
.text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.01em;
}
