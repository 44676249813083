.formGroup {
    height: 110px;
    font-size: 14px;
}

.inputAndButton{
    display:flex;
    width: 100% ;
    align-items: center;
    justify-content: center;
}


.input {
    width: 350px;
    height: 50px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 50px;
}

.input:focus {
    outline: none !important;
}

.input.active {
    border-color: #f7685b;
}

.button{
    width: 63px;
    height: 50px;
    background-color: #04c35c;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: -50px;
}

.label {
    margin-bottom: 11px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    padding: 0;
}

.alert {
    display: none;
    align-items: center;
    margin-top: 5px;
    padding-left: 1px;
}

.alert.active {
    display: flex;
}

.star {
    display: none;
    color: #f7685b;
}

.star.active {
    display: initial;
}

.icon {
    font-size: 12px;
    color: #f7685b;
    padding-bottom: 2px;
    margin-right: 8px;
}

.labelError {
    color: #f7685b;
    font-size: 12px;
}
