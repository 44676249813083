@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("../node_modules/font-awesome/css/font-awesome.min.css");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
    height: 8px;
    width: 4px;
}
::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.select-container.ant-form-item-control-wrapper {
    width: 100%;
}
.ant-input-number{
    width: 100%;
}
.ant-picker {
    width: 100%;
}

.ant-form-item {
    margin-bottom: 10px;
    margin-top: 10px;
}