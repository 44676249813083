.container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 38px 50px 0px 50px;
}

.logo {
    display: flex;
}

.logo img {
    width: 26px;
    height: 26px;
    position: relative;
    right: 8px;
}

.logo > p {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #22242c;
}

.navigation {
    display: flex;
}

.navigation li {
    list-style: none;
    padding-left: 28px;
}

.navigation li > a {
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}
