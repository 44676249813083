.container {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #2d3748;
}

.radio {
    background: #f7fafc;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    margin-right: 11px;
}
