.background {
    /* Set rules to fill background */
    min-height: 100%;
    min-width: 1024px;

    /* Set up proportionate scaling */
    width: 100%;
    height: 100%;

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}
