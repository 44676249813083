@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
.AuthLayout_background__1EPqf {
    /* Set rules to fill background */
    min-height: 100%;
    min-width: 1024px;

    /* Set up proportionate scaling */
    width: 100%;
    height: 100%;

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}

.Header_container__1VC87 {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 38px 50px 0px 50px;
}

.Header_logo__3_SJs {
    display: flex;
}

.Header_logo__3_SJs img {
    width: 26px;
    height: 26px;
    position: relative;
    right: 8px;
}

.Header_logo__3_SJs > p {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #22242c;
}

.Header_navigation__1NvSa {
    display: flex;
}

.Header_navigation__1NvSa li {
    list-style: none;
    padding-left: 28px;
}

.Header_navigation__1NvSa li > a {
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}

.ItemNavigation_link__2X4Zj {
    text-decoration: none;
    color: #334d6e;
}

.ItemNavigation_linkLogout__29k_- {
    text-decoration: none;
    color: rgba(247, 104, 91, 1);
    width: 100%;
}

.ItemNavigation_linkLogout__29k_-:hover {
    color: rgba(247, 104, 91, 1);
}

.ItemNavigation_link__2X4Zj:hover * {
    color: #04c35c;
}

.ItemNavigation_link__2X4Zj.ItemNavigation_active__3Sp4Y * {
    color: #04c35c;
}

.HomeLayout_container__rgxc- {
    height: calc(100% - 50px);
}

.HomeLayout_toolbar__4FdN3 {
    min-height: 50px;
}

/* @media screen and (max-width: 1280px) {
    .container {
        height: 100%;
    }
} */

.Button_button__3QF_k {
    background-color: #04c35c;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100%;
    height: 50px;
}

.FormContainer_container___Ycjx {
    position: relative;
    width: 450px;
    background: #ffffff;
    padding: 50px;
    margin: 66px auto;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
}

.FormContainer_text__c7UC0 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #1a202c;
}

.Input_formGroup__3lYmc {
    height: 120px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.Input_input__3PNbO {
    width: 100%;
    height: 50px;
    font-style: normal;
    line-height: 16px;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 16px;
    padding-right: 16px;
}

.Input_input__3PNbO::-webkit-input-placeholder {
    color: rgba(194, 207, 224, 1);
}

.Input_input__3PNbO::placeholder {
    color: rgba(194, 207, 224, 1);
}
.Input_input__3PNbO:focus {
    outline: none !important;
}

.Input_input__3PNbO[type="time"]::-webkit-datetime-edit-text {
    padding: 19px 4px;
}

.Input_input__3PNbO[type="time"]::-webkit-datetime-edit-text {
    padding: 19px 4px;
}

.Input_input__3PNbO[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0.5;
}

.Input_input__3PNbO.Input_active__1-cWB {
    border-color: #f7685b;
}

.Input_label__z-LP7 {
    margin-bottom: 11px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    padding: 0;
}

.Input_alert__1M2M5 {
    display: none;
    align-items: center;
    margin-top: 5px;
    padding-left: 1px;
}

.Input_alert__1M2M5.Input_active__1-cWB {
    display: flex;
}

.Input_star__3mJKd {
    display: none;
    color: #f7685b;
}

.Input_star__3mJKd.Input_active__1-cWB {
    display: initial;
}

.Input_icon__1DpGS {
    font-size: 12px;
    color: #f7685b;
    padding-bottom: 2px;
    margin-right: 8px;
}

.Input_labelError__K5IF- {
    color: #f7685b;
    font-size: 12px;
}

@media screen and (max-width: 1280px) {
    .Input_label__z-LP7 {
        font-size: 11px;
    }

    .Input_input__3PNbO {
        padding-right: 8px;
        font-size: 13px;
    }
}

.SignIn_form__LV5DE {
    display: flex;
    flex-direction: column;
}

.SignIn_formGroup__Yx9VX {
    display: flex;
    justify-content: space-between;
    margin-bottom: 37px;
}

.SignIn_checkbox__21qvL {
    border-radius: 50%;
}

.SignIn_linkForgotPassword__35K4U {
    text-decoration: none;
    color: #2c5282;
}

.SignIn_footerForm__2EoM5 {
    position: relative;
    margin-top: 152px;
    display: flex;
    justify-content: center;
}

.SignIn_linkSignUp__2Hqh7 {
    color: #04c35c;
    text-decoration: none;
}

.SignIn_alertLabel__3g98S {
    font-size: 14px;
    height: 24px;
    text-align: center;
    margin: 0;
    left: 50%;
    color: #f7685b;
}

.SignUp_containerRadios__1nZbG {
    display: flex;
    justify-content: space-between;
    margin: 30px auto 56px auto;
}

.SignUp_containerCheckbox__3gf6N {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}

.SignUp_checkbox__3f0To {
    margin-right: 10px;
}

.SignUp_checkboxLabel__1ocFy > a {
    color: #04c35c;
    text-decoration: none;
}

.SignUp_button__1A7wg {
    margin-top: 45px;
}

.SignUp_alertLabel__3lbSF {
    font-size: 14px;
    height: 24px;
    text-align: center;
    margin: 0;
    left: 50%;
    color: #f7685b;
}

.Radio_container__1g17x {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #2d3748;
}

.Radio_radio__2iCo_ {
    background: #f7fafc;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    margin-right: 11px;
}

.ForgotPassword_warning__8pMlF {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
.ForgotPassword_requestChangePassword__FYH_z {
    margin-bottom: 15px;
}
.ForgotPassword_buttonConfirmChange__2jpoH {
    margin-top: 45px;
}
.ForgotPassword_alertLabel__3Wj2I {
    font-size: 14px;
    height: 24px;
    text-align: center;
    margin: 0;
    left: 50%;
    color: #f7685b;
}

.InputButton_formGroup__3MlDh {
    height: 110px;
    font-size: 14px;
}

.InputButton_inputAndButton__3VD84{
    display:flex;
    width: 100% ;
    align-items: center;
    justify-content: center;
}


.InputButton_input__1AduZ {
    width: 350px;
    height: 50px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 50px;
}

.InputButton_input__1AduZ:focus {
    outline: none !important;
}

.InputButton_input__1AduZ.InputButton_active__1T1Wd {
    border-color: #f7685b;
}

.InputButton_button__1IMvs{
    width: 63px;
    height: 50px;
    background-color: #04c35c;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: -50px;
}

.InputButton_label__4OZhV {
    margin-bottom: 11px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    padding: 0;
}

.InputButton_alert__bBtYb {
    display: none;
    align-items: center;
    margin-top: 5px;
    padding-left: 1px;
}

.InputButton_alert__bBtYb.InputButton_active__1T1Wd {
    display: flex;
}

.InputButton_star__2xAM6 {
    display: none;
    color: #f7685b;
}

.InputButton_star__2xAM6.InputButton_active__1T1Wd {
    display: initial;
}

.InputButton_icon__1U_s8 {
    font-size: 12px;
    color: #f7685b;
    padding-bottom: 2px;
    margin-right: 8px;
}

.InputButton_labelError__3M8-Y {
    color: #f7685b;
    font-size: 12px;
}

.Loading_loading__2JaUi {
    width: 15%;
    height: 15%;
}

.AutoCompleteInput_star__1HsyG {
    display: none;
}

.AutoCompleteInput_star__1HsyG.AutoCompleteInput_active__1QgCj {
    display: initial;
    color: #f7685b;
}

.AutoCompleteInput_labelActive__1gqai {
    display: none;
}

.UploadImage_container__hEZKs {
    width: 100%;
    height: 77%;
    padding: 15px 15px 0px 15px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
}
.UploadImage_images__29Z6P {
    display: flex;
    flex-direction: row;
    overflow: none;
}
.UploadImage_imagePreview__35U-u {
    width: 140px;
    height: 78px;
}
.UploadImage_imagePreview__35U-u > img {
    width: 90%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.BoxNumber_boxNumber__36Lhk {
    width: 20px;
    height: 20px;
    border: 1px solid #04c35c;
    background-color: #04c35c;
    border-radius: 4px;
}
.BoxNumber_text__3TYVq {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.01em;
}

.ModalAdd_form__28QeS {
    width: 1300px;
}

@media screen and (max-width: 1280px) {
    .ModalAdd_form__28QeS {
        width: 1200px;
    }
}

.ModalAdd_form__2ESZ8 {
    width: 1300px;
}

@media screen and (max-width: 1280px) {
    .ModalAdd_form__2ESZ8 {
        width: 1200px;
    }
}

.ModalUpdate_form__214eO {
    width: 1300px;
}

@media screen and (max-width: 1280px) {
    .ModalUpdate_form__214eO {
        width: 1200px;
    }
}

.ModalUpdate_form__2cZlq {
    width: 1300px;
}

@media screen and (max-width: 1280px) {
    .ModalUpdate_form__2cZlq {
        width: 1200px;
    }
}

.ModalAdd_form__2lmfM {
    width: 1300px;
}

@media screen and (max-width: 1280px) {
    .ModalAdd_form__2lmfM {
        width: 1200px;
    }
}

.SelectInput_star__3dBUt {
    display: none;
}

.SelectInput_star__3dBUt.SelectInput_active__2yxWy {
    display: initial;
    color: #f7685b;
}

.SelectInput_labelActive__2iLbH {
    display: none;
}

.ModalDetail_form__3Q9lc {
    width: 1300px;
}

@media screen and (max-width: 1280px) {
    .ModalDetail_form__3Q9lc {
        width: 1200px;
    }
}

.ModalAdd_form__1yKz9 {
    width: 1300px;
}

@media screen and (max-width: 1280px) {
    .ModalAdd_form__1yKz9 {
        width: 1200px;
    }
}

.ModalAdd_form__1BR1j {
    width: 1300px;
}

@media screen and (max-width: 1280px) {
    .ModalAdd_form__1BR1j {
        width: 1200px;
    }
}

.ModalUpdate_form__AEGij {
    width: 1300px;
}

@media screen and (max-width: 1280px) {
    .ModalUpdate_form__AEGij {
        width: 1200px;
    }
}

.NotFound_container__2TrlD {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.NotFound_header__3ujaN {
    font-weight: bold;
    font-size: 60px;
    line-height: 82px;
    font-style: normal;
    color: rgba(129, 140, 139, 1);
    font-family: Nunito;
}

.NotFound_body__f1BFP {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    color: #2d4642;
}
.NotFound_containerButton__Vce17 {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
}

.NotFound_linkToHome__103hy {
    background-color: rgba(4, 195, 92, 1);
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
    padding: 16px 24px;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    border-radius: 5px;
}

.NotFound_linkToHome__103hy:hover {
    color: rgba(255, 255, 255, 1);
}

.NotFound_linkToFacebook__1rq68 {
    background-color: rgba(255, 255, 255, 1);
    text-decoration: none;
    color: rgba(4, 195, 92, 1);
    padding: 16px 24px;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    border-radius: 5px;
    border: 1px solid #c6cecd;
}

.NotFound_linkToFacebook__1rq68:hover {
    color: rgba(4, 195, 92, 1);
}

.NotFound_linkToHome__103hy > p,
.NotFound_linkToFacebook__1rq68 > p {
    margin: 0;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
    height: 8px;
    width: 4px;
}
::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.select-container.ant-form-item-control-wrapper {
    width: 100%;
}
.ant-input-number{
    width: 100%;
}
.ant-picker {
    width: 100%;
}

.ant-form-item {
    margin-bottom: 10px;
    margin-top: 10px;
}
